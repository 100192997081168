$background: #000;
@use "animations.scss";
* {
    margin: 0;
    padding: 0;
    font-family: "CircularStd";
    font-weight: 100;
    color: #fff;
}

html,
body {
    overflow-x: hidden;
}

body {
    background-color: $background;
}

.app_container {
    padding-bottom: 300px;
}

.logos_container {
    display: flex;
    justify-content: center;
    animation-name: unscale;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-delay: 2s;
    transform: scale(2);
    margin-top: 200px;
    .luminosity_logo {
        max-width: 300px;
        padding: 30px;
        animation-duration: 0.5s;
        animation-delay: 1s;
        position: relative;
        left: 50px;
        opacity: 0;
        animation-fill-mode: forwards;
        animation-name: moveLuminosityLogo;
    }
    .gsv_logo {
        padding: 30px;
        animation-duration: 0.5s;
        animation-delay: 1s;
        position: relative;
        left: -50px;
        opacity: 0;
        animation-fill-mode: forwards;
        animation-name: moveGSVLogo;
    }
    .divider {
        width: 1px;
        background: #555;
        transform: rotate(0deg);
        transition: opacity 0.5s;
        animation-duration: 0.5s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        animation-name: rotatedivider;
    }
}

.intro {
    color: #999;
    text-align: center;
    font-size: 18pt;
    width: 500px;
    margin-top: 100px;
    animation-name: loadIntro;
    animation-delay: 2s;
    animation-duration: 0.5s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    .card {
        width: 300px;
        height: 180px;
        padding: 10px;
        border-radius: 20px;
        color: #fff;
        font-size: 18px;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 10px 10px 20px -10px rgba(255, 255, 255, 0.2);
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        transition: 0.5s ease;
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card:hover {
        z-index: +500;
    }
}

.section {
    background: #000;
    margin-top: 100px;
    .section_title {
        text-align: center;
    }
    .section_desc {
        margin-top: 20px;
        color: #999;
        text-align: center;
    }
    animation-name: loadSections;
    animation-duration: 0.5s;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}


@media screen and (max-width: 500px) {
    .logos_container {
        display: flex;
        flex-wrap: wrap !important;
        width: 100% !important;
        position: relative !important;
        left: 0% !important;
        transform: scale(1.5) !important;
        animation-name: unscaleMobile !important;
        animation-fill-mode: forwards !important;
        animation-duration: 0.5s !important;
        animation-delay: 2s !important;
        .luminosity_logo {
            width: 200px;
        }
    }

    .divider {
        transform: rotate(0deg) !important;
        height: 1px !important;
        flex-basis: 100%;
        margin-left: 100px;
        margin-right: 100px;
        background-color: #fff;
        animation: none !important;
    }
    .intro {
        width: 300px;
        font-size: 15pt;
        margin: 30px;
    }
}