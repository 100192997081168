@keyframes rotatedivider {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(15deg);
    }
}

@keyframes moveLuminosityLogo {
    from {
        left: 50px;
        opacity: 0;
    }

    to {
        left: 0px;
        opacity: 1;
    }
}

@keyframes moveGSVLogo {
    from {
        left: -50px;
        opacity: 0;
    }

    to {
        left: 0px;
        opacity: 1;
    }
}

@keyframes unscale {
    from {
        margin-top: 200px;
        transform: scale(2);
    }

    to {
        margin-top: 80px;
        transform: scale(1.4);
    }
}

@keyframes loadIntro {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes loadSections {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes moveLuminosityLogoMobile {
    from {
        left: 50px;
        opacity: 0;
    }

    to {
        left: 0px;
        opacity: 1;
    }
}

@keyframes moveGSVLogoMobile {
    from {
        left: -50px;
        opacity: 0;
    }

    to {
        left: 0px;
        opacity: 1;
    }
}

@keyframes unscaleMobile {
    from {
        margin-top: 200px;
        transform: scale(1.5);
    }

    to {
        margin-top: 50px;
        transform: scale(1);
    }
}